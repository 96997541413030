export const state = () => ({
  list: [], // Holds the list of certificates
});

export const getters = {
  list: (state) => state.list || [], // Retrieve the list from state
};

export const mutations = {
  setList(state, payload) {
    state.list = payload || []; // Update the list in state
  },
  deleteItem(state, id) {
    // Find the item by ID and remove it from the state
    const index = state.list.findIndex((item) => item.id === id);
    if (index !== -1) {
      state.list.splice(index, 1); // Remove item from the list
    }
  },
};


export const actions = {
  async fetch({ commit }, params) {
    try {
      const res = await this.$api.get("/admin/common/certificate/list/", { params });
      if (res && res.data) {
        commit("setList", res.data.results || []); // Update the list in state
        return res.data;
      }
    } catch (error) {
      console.error("Fetch API Error:", error);
      throw error;
    }
  },
  async removeItemAction({ commit }, id) {
    try {
      // Send DELETE request to the API
      const res = await this.$api.delete(`/admin/common/certificate/${id}/delete/`);

      // If the request is successful, commit the deletion and return the response
      if (res && res.status === 204) {
        commit("deleteItem", id); // Update state by removing the item
        return res;
      } else {
        throw new Error("Unexpected response from server.");
      }
    } catch (error) {
      console.error("Delete API Error:", error);
      throw error; // Propagate the error to the component
    }
  },
};

